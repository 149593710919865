function handleFormLabelPosition() {
    var regularFields = $(".form-control:not(.search-field):not(.quantity-field), .custom-select");

    regularFields.each(function () {
        var $field = $(this);

        $field.on("change", function () {
            if ($field.val() && $field.val() !== "") {
                $field.addClass("field-with-value");
            } else {
                $field.removeClass("field-with-value");
            }
        });
    });
}

module.exports = {
    handleFormLabelPosition: handleFormLabelPosition
};
