var keyboardAccessibility = require("base/components/keyboardAccessibility");

var clearSelection = function (element) {
    $(element).closest(".custom-dropdown.show").children(".nav-link, .custom-dropdown-link").attr("aria-expanded", "false");
    $(element).closest(".custom-dropdown.show").children(".custom-dropdown-menu").attr("aria-hidden", "true");
    $(element).closest(".custom-dropdown.show").removeClass("show");
    $(element).removeClass("show");
    $(element).closest(".custom-dropdown-menu").removeClass("submenu-opened");
    if ($(element).children(".nav-link").length) {
        $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "false");
    } else {
        $("div.menu-group > ul.nav.navbar-nav > li.nav-item > ul.custom-dropdown-menu > li.custom-dropdown-item > a").attr("aria-hidden", "false");
    }
    $(element).closest(".custom-dropdown").children(".custom-dropdown-menu").children(".top-category")
        .detach();
};

var isDesktop = function (element) {
    return $(element).parents(".menu-toggleable-left").css("position") !== "fixed";
};

var openMenu = function (element, e) {
    if ($(".modal-background").hasClass("close-search-modal")) {
        $(".modal-background.close-search-modal").trigger("click");
    }

    var $this = $(element);
    var $parent = $this.closest(".custom-dropdown");

    if ($parent.hasClass("show")) {
        return;
    }

    if (!isDesktop(element)) {
        // copy parent element into current UL
        var ariaLabelBack = $(".navbar").data("back-label");
        var li = $("<li class='custom-dropdown-item top-category' role='button'></li>");
        var link = $(element)
            .clone()
            .removeClass("custom-dropdown-toggle")
            .removeAttr("data-toggle")
            .removeAttr("aria-expanded")
            .attr("aria-haspopup", "false")
            .attr("aria-label", ariaLabelBack);
        li.append(link);
        $(element).parent().children(".custom-dropdown-menu").prepend(li).attr("aria-hidden", "false");
        // copy navigation menu into view
        $(element).parent().addClass("show");
        $(element).attr("aria-expanded", "true");
        $(link).focus();
        if ($(element).hasClass("custom-dropdown-link")) {
            $("div.menu-group > ul.nav.navbar-nav > li.nav-item > ul.custom-dropdown-menu > li.custom-dropdown-item > a").attr("aria-hidden", "true");
        } else {
            $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "true");
        }
        e.preventDefault();
    } else {
        e.preventDefault();
        var eventElement = element;
        $(".navbar-nav > li").each(function () {
            if (!$.contains(this, eventElement)) {
                $(this)
                    .find(".show")
                    .each(function () {
                        clearSelection(this);
                    });
                if ($(this).hasClass("show")) {
                    $(this).removeClass("show");
                    $(this).closest(".custom-dropdown-menu").removeClass("submenu-opened");
                    $(this).children("ul.custom-dropdown-menu").removeClass("show");
                    $(this).children("ul.custom-dropdown-menu").attr("aria-hidden", "true");
                    $(this).children(".nav-link").attr("aria-expanded", "false");
                }
            }
        });
        // need to close all the custom-dropdowns that are not direct parent of current custom-dropdown
        $(element).parent().addClass("show");
        $(element).closest(".custom-dropdown-menu").addClass("submenu-opened");
        $(element).siblings(".custom-dropdown-menu").addClass("show");
        $(element).siblings(".custom-dropdown-menu").attr("aria-hidden", "false");
        $(element).attr("aria-expanded", "true");
        $(".header-wrapper").addClass("menu-opened");
        $("body").addClass("modal-open");
        $(".modal-background").addClass("close-menu-modal").show();
    }
};

module.exports = function () {
    keyboardAccessibility(
        ".main-menu .nav-link, .main-menu .custom-dropdown-link",
        {
            40: function (menuItem) {
                if ($(".modal-background").hasClass("close-search-modal")) {
                    $(".modal-background.close-search-modal").trigger("click");
                }

                // down
                if (menuItem.hasClass("nav-item")) {
                    // top level
                    $(".navbar-nav .show").removeClass("show").children(".custom-dropdown-menu").removeClass("show");
                    menuItem.addClass("show").children(".custom-dropdown-menu").addClass("show");
                    menuItem.find("ul > li > a")
                        .first()
                        .focus();

                    if (menuItem.hasClass("custom-dropdown")) {
                        menuItem.closest(".custom-dropdown-menu").addClass("submenu-opened");
                        $(".header-wrapper").addClass("menu-opened");
                        $("body").addClass("modal-open");
                        $(".modal-background").addClass("close-menu-modal").show();
                    }
                } else {
                    menuItem.removeClass("show").children(".custom-dropdown-menu").removeClass("show");
                    if (!(menuItem.next().length > 0)) {
                        // if this is the last menuItem
                        menuItem
                            .parent()
                            .parent()
                            .find("li > a") // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) {
                // right
                if (menuItem.hasClass("nav-item")) {
                    // top level
                    menuItem.removeClass("show").children(".custom-dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass("custom-dropdown")) {
                    menuItem.addClass("show").children(".custom-dropdown-menu").addClass("show");
                    $(this).attr("aria-expanded", "true");
                    menuItem.find("ul > li > a").first().focus();
                }
            },
            38: function (menuItem) {
                // up
                if (menuItem.hasClass("nav-item")) {
                    // top level
                    menuItem.removeClass("show").children(".custom-dropdown-menu").removeClass("show");
                    if (menuItem.hasClass("custom-dropdown")) {
                        menuItem.closest(".custom-dropdown-menu").removeClass("submenu-opened");
                        $(".header-wrapper").removeClass("menu-opened");
                        $("body").removeClass("modal-open");
                        $(".modal-background").removeClass("close-menu-modal").hide();
                    }
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass("show")
                        .children(".nav-link")
                        .attr("aria-expanded", "false");
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) {
                // left
                if (menuItem.hasClass("nav-item")) {
                    // top level
                    menuItem.removeClass("show").children(".custom-dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.prev().children().first().focus();
                    if (menuItem.hasClass("custom-dropdown")) {
                        menuItem.closest(".custom-dropdown-menu").removeClass("submenu-opened");
                        $(".header-wrapper").removeClass("menu-opened");
                        $("body").removeClass("modal-open");
                        $(".modal-background").removeClass("close-menu-modal").hide();
                    }
                } else {
                    menuItem
                        .closest(".show")
                        .removeClass("show")
                        .closest("li.show")
                        .removeClass("show")
                        .children()
                        .first()
                        .focus()
                        .attr("aria-expanded", "false");
                }
            },
            27: function (menuItem) {
                // escape
                var parentMenu = menuItem.hasClass("show") ? menuItem : menuItem.closest("li.show");
                parentMenu.children(".show").removeClass("show");
                parentMenu.removeClass("show").children(".nav-link").attr("aria-expanded", "false");
                parentMenu.children().first().focus();
                if (menuItem.hasClass("nav-item custom-dropdown")) {
                    menuItem.closest(".custom-dropdown-menu").removeClass("submenu-opened");
                    $(".header-wrapper").removeClass("menu-opened");
                    $("body").removeClass("modal-open");
                    $(".modal-background").removeClass("close-menu-modal").hide();
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $(".global-desktop-utility-links .dropdown:not(.disabled) [data-toggle='dropdown']")
        .on("mouseenter", function () {
            if (isDesktop(this) && !window.pdEditorOpen) {
                $(this).parent().addClass("show");
                $(this).siblings(".dropdown-menu").addClass("show").attr("aria-hidden", "false");
                $(this).attr("aria-expanded", "true");
            }
        })
        .parent()
        .on("mouseleave", function () {
            if (isDesktop(this) && !window.pdEditorOpen) {
                $(this).removeClass("show");
                $(this).children(".dropdown-menu").removeClass("show").attr("aria-hidden", "true");
                $(this).children(".dropdown-toggle").attr("aria-expanded", "false");
            }
        });

    $(".main-menu .custom-dropdown:not(.disabled) [data-toggle='custom-dropdown'].nav-link")
        .on("mouseover", function (e) {
            if (isDesktop(this) && !window.pdEditorOpen) {
                openMenu(this, e);
            }
        });
    
    $(".main-menu .custom-dropdown:not(.disabled) [data-toggle='custom-dropdown']")
        .on("click", function (e) {
            if ((isDesktop(this) && !$(this).hasClass("nav-link")) || (!isDesktop(this))) {
                openMenu(this, e);
            }
        });

    $(document).on("click", ".modal-background.close-menu-modal", function (e) {
        e.preventDefault();

        $(".navbar-nav > li").each(function () {
            $(this).find(".show").each(function () {
                clearSelection(this);
            });
            if ($(this).hasClass("show")) {
                $(this).removeClass("show");
                $(this).closest(".custom-dropdown-menu").removeClass("submenu-opened");
                $(this).children("ul.custom-dropdown-menu").removeClass("show");
                $(this).children("ul.custom-dropdown-menu").attr("aria-hidden", "true");
                $(this).children(".nav-link, .custom-dropdown-link").attr("aria-expanded", "false");
            }
        });

        $(".header-wrapper").removeClass("menu-opened");
        $("body").removeClass("modal-open");
        $(".modal-background").removeClass("close-menu-modal").hide();
    });

    $(".navbar-nav").on("click", ".close-button > button", function () {
        $(".modal-background.close-menu-modal").trigger("click");
    });

    $(".navbar-nav").on("click", ".nav-link:not(.custom-dropdown-toggle), .custom-dropdown-link:not(.custom-dropdown-toggle)", function (e) {
        var $this = $(this);

        if ($this.closest(".top-category").length) {
            return;
        }

        e.preventDefault();
        if (!isDesktop(this)) {
            $(".navbar-header .close-button").trigger("click");
        } else {
            $(".modal-background.close-menu-modal").trigger("click");
        }
        setTimeout(function () {
            window.location.href = $this.attr("href");
        }, 500);
    });

    $(".navbar-nav").on("click", ".top-category", function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $(".navbar-header").on("click", ".close-button", function (e) {
        e.preventDefault();
        $(".navbar-nav").find(".top-category").detach();
        $(".navbar-nav").find(".show").removeClass("show");
        $(".menu-toggleable-left").removeClass("in");
        $(".header").removeClass("menu-opened");
        $("body").removeClass("modal-open");
        $(".header").removeClass("store-locator-opened");

        $(".navbar-toggler").focus();

        $(".main-menu").attr("aria-hidden", "true");
        $("header").siblings().attr("aria-hidden", "false");
    });

    $(".navbar-toggler").click(function (e) {
        e.preventDefault();

        if ($(".modal-background").hasClass("close-search-modal")) {
            $(".modal-background.close-search-modal").trigger("click");
        }
        $(".main-menu").toggleClass("in");

        $(".header").toggleClass("menu-opened");
        $("body").toggleClass("modal-open");

        $(".main-menu").removeClass("d-none");
        $(".main-menu").attr("aria-hidden", "false");
        $("header").siblings().attr("aria-hidden", "true");

        $(".main-menu .nav.navbar-nav .nav-link").first().focus();
    });

    keyboardAccessibility(
        ".navbar-header .user",
        {
            40: function ($popover) {
                // down
                if ($popover.children("a").first().is(":focus")) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children("a").first().focus();
                }
            },
            38: function ($popover) {
                // up
                if ($popover.children("a").first().is(":focus")) {
                    $(this).focus();
                    $popover.removeClass("show");
                } else {
                    $popover.children("a").first().focus();
                }
            },
            27: function () {
                // escape
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            },
            9: function () {
                // tab
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            }
        },
        function () {
            var $popover = $(".user .popover li.nav-item");
            return $popover;
        }
    );

    $(".navbar-header .user").on("click", function (e) {
        var $popover = $(".navbar-header .user .popover");
        if ($popover.length > 0 && $popover.has(e.target).length <= 0 && $popover[0] !== e.target) {
            $popover.toggleClass("show");
            $(".user").attr("aria-expanded", $popover.hasClass("show"));
        }
    });

    $("body").on("click", function (e) {
        if ($(".navbar-header .user").has(e.target).length <= 0) {
            $(".navbar-header .user .popover").removeClass("show");
            $(".user").attr("aria-expanded", false);
        }
    });

    $("body").on("click", "#myaccount", function (event) {
        event.preventDefault();
    });

    $(window).on("breakpoint:change", function () {
        if ($(".menu-toggleable-left").hasClass("in")) {
            $(".navbar-header .close-button").trigger("click");
        } else if ($(".header-wrapper").hasClass("menu-opened")) {
            $(".modal-background.close-menu-modal").trigger("click");
        }
    });
};
