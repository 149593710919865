/**
 * returns unique coupons applied to all products in the purchase
 * @param {Array} items
 * @returns {Array} of unique coupons
 */
function getCheckoutCoupons(items) {
    var allCoupons = [];
    // eslint-disable-next-line array-callback-return
    items.map(function (item) {
        if (item.coupon) {
            allCoupons.push(item.coupon);
        }
    });

    var coupons = allCoupons.reduce((accumulator, item) => accumulator.concat(item), []); // make two dimension array to one dimension
    // remove duplicates
    var uniqueCoupons = coupons.filter(function (coupon, index) {
        return coupons.indexOf(coupon) == index;
    });

    return uniqueCoupons;
}

module.exports = function () {
    $(".submit-payment").on("click", function () {
        var methodID = $(".payment-information").data("payment-method-id");
        var ecommerce = $(".gtm-begin-checkout").data("gtm-begin-checkout");
        var items = ecommerce.items;
        var coupons = getCheckoutCoupons(items);
        ecommerce.coupon = coupons ? coupons : null;
        ecommerce.payment_type = methodID ? methodID : null;
        $("body").trigger("gtm:standartEvent", {
            event: "add_payment_info",
            data: ecommerce
        });
        var gtmPaymentObject = JSON.stringify(ecommerce);
        $(".gtm-add-payment-info").attr("data-gtm-add-payment-info", gtmPaymentObject);
    });

    $(".submit-shipping").on("click", function () {
        var gtmAddShippingObj = $(".gtm-add-shipping-info").data("gtm-add-shipping-info");
        if (gtmAddShippingObj) {
            $("body").trigger("gtm:standartEvent", {
                event: "add_shipping_info",
                data: gtmAddShippingObj
            });
        }
    });
};
