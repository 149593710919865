function validateForm() {
    var email = $("#email").val().trim();

    if (email === "") {
        alert("Please enter your email address."); // eslint-disable-line no-alert
        return false;
    }

    return true;
}

function initModalFromContentAsset() {
    var modal = $("#myModal");
    var contentAsset = modal.closest(".content-asset");
    var cookieName = contentAsset.data("cookie-name");
    var cookieAge = contentAsset.data("cookie-age");

    if (document.cookie.indexOf(cookieName + "=true") < 0) {
        modal.modal("show");

        modal.find(".close").click(function () {
            modal.modal("hide");
            document.cookie = cookieName + "=true; max-age=" + cookieAge + "; path=/";
        });
    }

    $("#newsletterform").submit(function (e) {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        var $form = $(this);
        var url = $form.attr("action");
        var data = $form.serialize();
        $.ajax({
            url: url,
            type: "POST",
            data: data,
            success: function (response) { /* eslint-disable-line no-unused-vars */
                console.log("Form submitted"); /* eslint-disable-line no-console */
            }
        });
    });
}

module.exports = {
    initModalFromContentAsset: initModalFromContentAsset
};
