var debounce = require("lodash/debounce");
var searchHelpers = require("./searchHelpers");
var endpoint = $(".suggestions-wrapper").data("url");
var minChars = 1;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings(".suggestions-wrapper");
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    var commonFeature = require("./commonFeature");
    return !!$(scope).closest(".search-mobile").length && commonFeature.isBreakpoint("<=sm");
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $("body").removeClass("modal-open");
    $("header").siblings().attr("aria-hidden", "false");
    $(".header").removeClass("menu-opened");
    $(".modal-background").removeClass("close-search-modal").hide();
    $(".search-toggler").removeClass("d-none");
    $(".navbar-toggler").removeClass("d-none");
    $(".store-locator-button").removeClass("d-none");
    $(".close-search-button").addClass("d-none");
    $(".site-search").removeClass("in");
    $(".site-search").attr("aria-hidden", "true");
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $("body").addClass("modal-open");
        $("header").siblings().attr("aria-hidden", "true");
        $(".header").addClass("menu-opened");
    }
    $(".modal-background").addClass("close-search-modal").show();
    $(".search-toggler").addClass("d-none");
    $(".close-search-button").removeClass("d-none");
    $(".site-search").addClass("in");
    $(".site-search").attr("aria-hidden", "false");
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;
    var windowHeight = $(window).height();

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find(".suggestions");
        $suggestions.css("top", top + outerHeight);
    } else if (windowHeight > 600) {
        $(".site-search").css("height", 635);
    } else {
        $(".site-search").css("height", windowHeight - 44);
        $(".site-search .suggestions").css("min-height", windowHeight - 44);
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).find(".suggestions-inner").empty();
    $(".site-search").css("height", "inherit");

    $.spinner().stop();

    if (typeof (response) !== "object") {
        getSuggestionsWrapper(this).find(".popular-suggestions").addClass("d-none");
        $suggestionsWrapper.append(response).show();
        $(this).siblings(".reset-button").removeClass("d-none");
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $(".suggestions .item");
        if ($(suggestionsList).length) {
            $("input.search-field").attr("aria-describedby", "search-result-count");
        } else {
            $("input.search-field").removeAttr("aria-describedby");
        }
    } else {
        $suggestionsWrapper.hide();
        $(".site-search").css("height", "inherit");
        getSuggestionsWrapper(this).find(".popular-suggestions").removeClass("d-none");
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: "GET",
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        $(scope).siblings(".reset-button").addClass("d-none");
        getSuggestionsWrapper(scope).find(".suggestions-inner").empty();
        getSuggestionsWrapper(scope).find(".popular-suggestions").removeClass("d-none");
        $(".site-search").css("height", "inherit");
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $(".suggestions .item");
    if (suggestionsList.filter(".selected").length === 0) {
        suggestionsList.first().addClass("selected");
        $("input.search-field").each(function () {
            $(this).attr("aria-activedescendant", suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass("selected")) {
                $(this).removeClass("selected");
                $(this).removeAttr("aria-selected");
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass("selected");
                    suggestionsList.eq(idx).attr("aria-selected", true);
                    $(this).removeProp("aria-selected");
                    $("input.search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass("selected");
                    suggestionsList.first().attr("aria-selected", true);
                    $("input.search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

module.exports = function () {
    $("form[name='simpleSearch']").submit(function (e) {
        clearModals();
        $(".suggestions").hide();
        var suggestionsList = $(".suggestions .item");
        if (suggestionsList.filter(".selected").length !== 0) {
            e.preventDefault();
            suggestionsList.filter(".selected").find("a")[0].click();
        }
    });

    $("input.search-field").each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on("keyup focus", function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $(".site-search .reset-button").on("click", function () {
        $(this).addClass("d-none");
        $(".suggestions-inner").empty();
        $(".site-search").css("height", "inherit");
        $(".popular-suggestions").removeClass("d-none");
    });

    $(".search-toggler, .search-mobile form[role='search'] .search-field").on("click", function (e) {
        e.preventDefault();

        if ($(this).hasClass("search-toggler")) {
            if ($(".menu-toggleable-left").hasClass("in")) {
                $(".navbar-header .close-button").trigger("click");
            } else if ($(".header-wrapper").hasClass("menu-opened")) {
                $(".modal-background.close-menu-modal").trigger("click");
            }
        }

        applyModals(this);

        $(this).siblings(".site-search").find(".search-field").focus();
    });

    $(document).on("click", ".modal-background.close-search-modal", function () {
        searchHelpers.tearDownSuggestions();
    });

    $(".suggestions-wrapper").on("click", "a", function () {
        clearModals();
        $(".suggestions").hide();
    });

    $(document).on("click", function (e) {
        if (!$(".site-search").has(e.target).length && !$(e.target).hasClass(".search-toggler") && !$(".search-toggler").has(e.target).length && $(".modal-background").hasClass("close-search-modal")) {
            $(".modal-background.close-search-modal").trigger("click");
        }
    });

    $(".close-search-button").on("click", function () {
        $(".modal-background.close-search-modal").trigger("click");
    });

    $(window).on("breakpoint:change", function () {
        if ($(".site-search").hasClass("in")) {
            $(".modal-background.close-search-modal").trigger("click");
        }
    });
};
