/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the saveForLaterList
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
    } else {
        status = "alert-danger";
    }

    if ($(".add-to-saveForLaterList-messages").length === 0) {
        $("body").append("<div class='add-to-saveForLaterList-messages '></div>");
    }
    $(".add-to-saveForLaterList-messages").append(
        "<div class='add-to-saveForLaterList-alert alert text-center " + status + "'>" + data.msg + "</div>"
    );

    setTimeout(function () {
        $(".add-to-saveForLaterList-messages").remove();
        button.removeAttr("disabled");
    }, 5000);
}

module.exports = {
    addToList: function () {
        $(".add-to-list").on("click", function (e) {
            e.preventDefault();
            var url = $(this).data("href");
            var button = $(this);
            var pid = $(this).closest(".product-detail").find(".product-id").html() || $(this).data("pid");
            var optionId = $(this).closest(".product-detail").find(".product-option").attr("data-option-id");
            var optionVal = $(this)
                .closest(".product-detail")
                .find(".options-select option:selected")
                .attr("data-value-id");
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $(this).attr("disabled", true);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessage(data, button);
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    }
};
