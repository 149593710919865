/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} iconEmpty - icon that was clicked to add a product to the wishlist
 * @param {Object} iconFilled - icon that was clicked to remove a product from the wishlist
 */
function displayMessageAndChangeIcon(data, iconEmpty, iconFilled) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
        iconEmpty.toggleClass("d-none");
        iconFilled.toggleClass("d-none");
    } else {
        status = "alert-danger";
    }

    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append(
            "<div class='add-to-wishlist-messages '></div>"
        );
    }
    $(".add-to-wishlist-messages")
        .append("<div class='add-to-wishlist-alert text-center alert " + status + "'>" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 5000);
}

module.exports = {
    addToWishlist: function () {
        $("body").on("click", ".add-to-wish-list", function (e) {
            e.preventDefault();
            var iconEmpty = $(this).closest(".product-detail .product-main-region").find(".wishlist-add");
            var iconFilled = $(this).closest(".product-detail .product-main-region").find(".wishlist-remove");
            var pid = $(this).closest(".product-detail").find(".product-id").html();

            var url = "";
            if ($(this).data("url")) {
                url = $(this).data("url");
            }
            else {
                url = $(this).attr("href");
            }

            var optionId = $(this).closest(".product-detail").find(".product-option").attr("data-option-id");
            var optionVal = $(this).closest(".product-detail").find(".options-select option:selected").attr("data-value-id");
            optionId = optionId || null;
            optionVal = optionVal || null;
            var type = "get";
            var data = {};
            if (!url || !pid) {
                return;
            }
            if (!$(this).hasClass("wishlist-remove")) {
                data = {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                };
                type = "post";
            } else {
                url = url + "?pid=" + pid;
            }

            if ($(this).hasClass("wishlist-add")) {
                var gtmWishlistContainer = $(this).closest(".product-detail").find(".gtm-add-to-wishlist-container");
                var gtmAddToWishlistObj = gtmWishlistContainer.find(".gtm-add-to-wishlist").attr("data-add-to-wishlist");
                $("body").trigger("gtm:standartEvent", {
                    event: "add_to_wishlist",
                    data: JSON.parse(gtmAddToWishlistObj)
                });
            }

            $.spinner().start();

            $.ajax({
                url: url,
                type: type,
                dataType: "json",
                data: data,
                success: function (response) {
                    displayMessageAndChangeIcon(response, iconEmpty, iconFilled);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, iconEmpty, iconFilled);
                }
            });
        });
    }
};
