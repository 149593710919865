module.exports = function () {
    $("body").on("pinterest:trackEvent", function (event, data) {
        if (data && data.event) {
            pintrk('track', data.event, data.data); // eslint-disable-line
            console.log("Pinterest Pixel Test:","event: ", data.event, "object value if needed: ", data.data); // eslint-disable-line
        }
    });

    $("body").on("pinterest:updateAddToCartData", function (event, data) {
        var $pixelAddToCart = $(".pinterest-add-to-cart");

        if ($pixelAddToCart) {
            var pinterestAddToCartData = $pixelAddToCart.data("pinterest-add-to-cart");

            if (pinterestAddToCartData && data) {
                var product;
                if (data.bundleProduct) {
                    product = data.bundleProduct;
                } else if (data.product) {
                    product = data.product;
                } else if (data.products) {
                    product = data.products[0];
                }

                if (product) {
                    var price = product.price.sales.value ? product.price.sales.value : product.price.list.value;
                    var qty = product.selectedQuantity;

                    if (price && qty) {
                        price *= qty;
                        pinterestAddToCartData.value = price;
                        pinterestAddToCartData.order_quantity = qty;
                    }

                    pinterestAddToCartData.product_id = product.id;
                    pinterestAddToCartData.content_ids = [product.id];
                }
            }

            $pixelAddToCart.data("pinterest-add-to-cart", pinterestAddToCartData);
        }
    });

    $(document).on("click", "button.add-to-cart-drive-customizer", function () {
        var pinterestAddToCartObj = $(".pinterest-add-to-cart").data("pinterest-add-to-cart");
        $("body").trigger("pinterest:trackEvent", {
            event: "addtocart",
            data: pinterestAddToCartObj
        });
    });

    window.onload = function (e) {
        e.preventDefault();

        var pinterestCheckoutObj = $(".pinterest-checkout").data("pinterest-checkout");
        if (pinterestCheckoutObj) {
            $("body").trigger("pinterest:trackEvent", {
                event: "checkout",
                data: pinterestCheckoutObj
            });
        }

        var pinterestPageViewObj = $(".pinterest-page-view").data("pinterest-page-view");
        if (pinterestPageViewObj) {
            $("body").trigger("pinterest:trackEvent", {
                event: "pageview",
                data: pinterestPageViewObj
            });
        }
    };
};
