function getFullViewItemList(gtmObjArray) {
    var remainingArray = [];

    if (gtmObjArray && gtmObjArray.length > 0) {
        var newObject = gtmObjArray[0];
        gtmObjArray.splice(0, 1);
        gtmObjArray.forEach(function (itemObj) {
            if (itemObj.item_list_id === newObject.item_list_id) {
                newObject.items.push(itemObj.items);
            } else {
                remainingArray.push(itemObj);
            }
        });
        var items = newObject.items.reduce((accumulator, item) => accumulator.concat(item), []);
        newObject.items = items;
        newObject.items.map(function (item, index) { // eslint-disable-line array-callback-return
            item.index = index; // eslint-disable-line no-param-reassign
        });

        $("body").trigger("gtm:standartEvent", {
            event: "view_item_list",
            data: newObject
        });

        if (remainingArray && remainingArray.length > 0) {
            getFullViewItemList(remainingArray);
        }
    }
}

/**
 * updates GTM view_item_list dataLayer
 *
 */

module.exports = function () {
    $("body").on("gtm:standartEvent", function (event, data) {
        if (data && data.event && data.data) {
            var ecommerce = data.data;
            window.dataLayer.push({"event": data.event, ecommerce});    
        }
    });

    $(window).on("load", function (e) {
        e.preventDefault();

        var gtmViewItemListArray = Array.from(document.querySelectorAll(".gtm-view-item-list"));
        var gtmDataArray = [];
        if (gtmViewItemListArray && gtmViewItemListArray.length > 0) {
            gtmViewItemListArray.forEach(function (el) {
                gtmDataArray.push(JSON.parse(el.dataset.viewItemList));
            });
        }

        if (gtmDataArray.length > 0) {
            getFullViewItemList(gtmDataArray);
        }

        var gtmViewItemObj = $(".gtm-view-item").data("view-item");
        if (gtmViewItemObj) {
            $("body").trigger("gtm:standartEvent", {
                event: "view_item",
                data: gtmViewItemObj
            });
        }

        var gtmViewCartObj = $(".gtm-view-cart-list").data("view-cart-list");
        if (gtmViewCartObj) {
            $("body").trigger("gtm:standartEvent", {
                event: "view_cart",
                data: gtmViewCartObj
            });
        }

        var gtmCheckoutObj = $(".gtm-begin-checkout").data("gtm-begin-checkout");
        if (gtmCheckoutObj) {
            $("body").trigger("gtm:standartEvent", {
                event: "begin_checkout",
                data: gtmCheckoutObj
            });
        }

        var gtmPurchaseObj = $(".gtm-purchase").data("gtm-purchase");
        if (gtmPurchaseObj) {
            $("body").trigger("gtm:standartEvent", {
                event: "purchase",
                data: gtmPurchaseObj
            });
        }
    });

    $(".socialIcon").on("click", function () {
        var gtmShareObj = $(this).attr("data-gtm-share");
        $("body").trigger("gtm:standartEvent", {
            event: "share",
            data: JSON.parse(gtmShareObj)
        });
    });

    $(document).on("click", "button.add-to-cart-drive-customizer", function () {
        var addToCartContainer = $(this).closest(".cart-and-ipay").find(".gtm-add-to-cart-container");
        var gtmAddToCartObj = addToCartContainer.find(".gtm-add-to-cart").attr("data-gtm-add-to-cart");
        $("body").trigger("gtm:standartEvent", {
            event: "add_to_cart",
            data: JSON.parse(gtmAddToCartObj)
        });
    });

    $(document).on("click", ".tile-image, .pdp-link, .product-tile-image, .product-name-link", function () {
        var gtmSelectItemObj = $(this).closest(".product").find(".gtm-select-item").attr("data-gtm-select-item");
        $("body").trigger("gtm:standartEvent", {
            event: "select_item",
            data: JSON.parse(gtmSelectItemObj)
        });
    });

    $(document).on("click", ".minicart-links__wishlist", function (e) {
        e.preventDefault();

        var gtmAddToWishlistObj = $(this).attr("data-gtm-add-to-wishlist");
        $("body").trigger("gtm:standartEvent", {
            event: "add_to_wishlist",
            data: JSON.parse(gtmAddToWishlistObj)
        });
    });

    $(document).on("click", ".remove-product", function (e) {
        e.preventDefault();

        var gtmRemoveFromCart = $(this).attr("data-gtm-remove-from-cart");
        if (gtmRemoveFromCart) {
            $("body").trigger("gtm:standartEvent", {
                event: "remove_from_cart",
                data: JSON.parse(gtmRemoveFromCart)
            });
        }
    });
};
