const throttle = require("lodash/throttle");
const debounce = require("lodash/debounce");

let $window = $(window);

window.breakpointsFeature = window.breakpointsFeature || {
    breakpoints: {},
    sortedBreakpoints: []
};

function cacheWindowSize() {
    let height = $window.height();
    let width = window.innerWidth;
    if (width != window.breakpointsFeature.width) {
        let oldWidth = window.breakpointsFeature.width;
        window.breakpointsFeature.width = width;
        setActiveBreakpoint(width); // eslint-disable-line no-use-before-define
        $window.triggerHandler("windowresize:width", {
            paramName: "width",
            newValue: width,
            oldValue: oldWidth
        });
    }
    if (height != window.breakpointsFeature.height) {
        let oldHight = window.breakpointsFeature.height;
        window.breakpointsFeature.height = height;
        $window.triggerHandler("windowresize:height", {
            paramName: "height",
            newValue: height,
            oldValue: oldHight
        });
    }
}

function cacheScroll() {
    let scrollTop = $window.scrollTop();

    if (scrollTop != window.breakpointsFeature.scrollTop) {
        $window.triggerHandler("windowscroll", {
            paramName: "scroll",
            newValue: scrollTop,
            oldValue: window.breakpointsFeature.scrollTop
        });
        window.breakpointsFeature.scrollTop = scrollTop;
    }
}

function mapBreakpoints() {
    let breakpoints = {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        customLG: 1392,
        xl: 1440,
        xxl: 1590
    };
    let breakpointsList = [];
    if (!breakpoints) {
        breakpoints = {};
    }

    let breakpointsNames = Object.keys(breakpoints);

    // eslint-disable-next-line array-callback-return
    breakpointsNames.map(function (breakpointsName) {
        breakpointsList.push({
            name: breakpointsName,
            min: breakpoints[breakpointsName]
        });
    });

    window.breakpointsFeature.sortedBreakpoints = breakpointsList.sort(function (a, b) {
        return a.min - b.min;
    });
    window.breakpointsFeature.sortedBreakpoints = window.breakpointsFeature.sortedBreakpoints.map(function (
        breakpoint,
        i
    ) {
        let nextBreakpointIndex = i + 1;
        breakpoint.smaller = breakpointsNames.slice(0, i); // eslint-disable-line no-param-reassign
        breakpoint.bigger = breakpointsNames.slice(nextBreakpointIndex); // eslint-disable-line no-param-reassign
        if (window.breakpointsFeature.sortedBreakpoints[nextBreakpointIndex]) {
            breakpoint.max = window.breakpointsFeature.sortedBreakpoints[nextBreakpointIndex].min - 0.2; // eslint-disable-line no-param-reassign
        } else {
            breakpoint.max = 99999; // eslint-disable-line no-param-reassign
        }
        window.breakpointsFeature.breakpoints[breakpoint.name] = breakpoint;
        return breakpoint;
    });
    setActiveBreakpoint(); // eslint-disable-line no-use-before-define
}

function setActiveBreakpoint(width) {
    checkBreakpoints(); // eslint-disable-line no-use-before-define
    if (!width) {
        width = window.innerWidth; // eslint-disable-line no-param-reassign
    }
    // eslint-disable-next-line consistent-return
    $.each(window.breakpointsFeature.sortedBreakpoints, function (i, breakpoint) {
        if (breakpoint.min <= width && breakpoint.max >= width) {
            if (breakpoint !== window.breakpointsFeature.currentBreakpoint) {
                if (window.breakpointsFeature.currentBreakpoint) {
                    let prevBreakpointName = window.breakpointsFeature.currentBreakpoint.name;
                    window.breakpointsFeature.currentBreakpoint = breakpoint;
                    $window.triggerHandler("breakpoint:change", {
                        previous: prevBreakpointName,
                        current: getCurrentBreakpointName(), // eslint-disable-line no-use-before-define
                        currentBreakpoint: getCurrentBreakpointObj() // eslint-disable-line no-use-before-define
                    });
                } else {
                    window.breakpointsFeature.currentBreakpoint = breakpoint;
                    $window.triggerHandler("breakpoint:set", {
                        current: getCurrentBreakpointName(), // eslint-disable-line no-use-before-define
                        currentBreakpoint: getCurrentBreakpointObj() // eslint-disable-line no-use-before-define
                    });
                }
            }
            return false;
        }
    });
    if (!window.breakpointsFeature.currentBreakpoint) {
        window.breakpointsFeature.currentBreakpoint = {}; // maybe change
    }
}

function getCurrentBreakpointObj() {
    checkBreakpoints(); // eslint-disable-line no-use-before-define
    return { ...window.breakpointsFeature.currentBreakpoint };
}

function getCurrentBreakpointName() {
    checkBreakpoints(); // eslint-disable-line no-use-before-define
    return window.breakpointsFeature.currentBreakpoint && window.breakpointsFeature.currentBreakpoint.name;
}

function isBreakpoint(breakpointQuery) {
    checkBreakpoints(); // eslint-disable-line no-use-before-define
    let result = false;

    if (!breakpointQuery || typeof breakpointQuery !== "string") {
        console.warn("Wrong breakpoint query - " + breakpointQuery); // eslint-disable-line no-console
        return result;
    }

    let breakpointNameQuery = breakpointQuery.replace(/[<=> ]/g, "");
    let targetBrekpoint = window.breakpointsFeature.breakpoints[breakpointNameQuery];

    if (!targetBrekpoint) {
        console.warn("Wrong breakpoint query - " + breakpointQuery); // eslint-disable-line no-console
        return result;
    }

    let isSmallerQuery = breakpointQuery.indexOf("<") > -1;
    let isBiggerQuery = breakpointQuery.indexOf(">") > -1;
    let isEquelQuery = breakpointQuery.indexOf("=") > -1 || !(isSmallerQuery || isBiggerQuery);

    let currentBreakpointName = window.breakpointsFeature.currentBreakpoint.name;

    if (isEquelQuery && currentBreakpointName == breakpointNameQuery) {
        result = true;
    } else if (
        isSmallerQuery &&
        targetBrekpoint.smaller &&
        targetBrekpoint.smaller.indexOf(currentBreakpointName) > -1
    ) {
        result = true;
    } else if (isBiggerQuery && targetBrekpoint.bigger && targetBrekpoint.bigger.indexOf(currentBreakpointName) > -1) {
        result = true;
    }

    return result;
}

function getBreakpointMin(breakpointName) {
    checkBreakpoints(); // eslint-disable-line no-use-before-define
    if (
        !window.breakpointsFeature.breakpoints ||
        typeof window.breakpointsFeature.breakpoints[breakpointName] == "undefined"
    ) {
        return null;
    }
    return window.breakpointsFeature.breakpoints[breakpointName].min;
}

function checkBreakpoints() {
    if (window.breakpointsFeature.sortedBreakpoints.length == 0) {
        mapBreakpoints();
    }
}

/**
 * Checks if element is in viewport
 * @param {jQuery} $this - element to check if it's in viewport or not
 * @param {boolean} withoutHeader - if true than header's height will be taken into account
 */
function isInViewport($this, withoutHeader) {
    let $headerBanner = $(".header-banner");
    let $navigation = $(".header-wrapper");
    let headerBannerHeight = $headerBanner.length ? $headerBanner.outerHeight() : 0;
    let navigationHeight = $navigation.length ? $navigation.outerHeight() : 0;
    let elementTop = $this.offset().top;
    let headerHeight = withoutHeader ? headerBannerHeight + navigationHeight : 0;
    let elementBottom = elementTop - headerHeight + $this.outerHeight();

    let viewportTop = $window.scrollTop();
    let viewportBottom = viewportTop + $window.height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

let commonFeature = {
    init: function () {
        mapBreakpoints();
    },
    initEvents: function () {
        if ($window.data("sizeEventInitialized")) {
            return;
        }
        $window.on("windowresize:width windowresize:height", function (_e, params) {
            $window.triggerHandler("windowresize", params);
        });
        $window.on("resize", debounce(cacheWindowSize, 50));
        cacheWindowSize();

        $window.on("scroll", throttle(cacheScroll, 50));
        cacheScroll();
        $window.data("sizeEventInitialized", true);
    },
    checkBreakpoints: checkBreakpoints,
    getBreakpointMin: getBreakpointMin,
    isBreakpoint: isBreakpoint,
    setActiveBreakpoint: setActiveBreakpoint,
    getCurrentBreakpoint: getCurrentBreakpointName,
    isInViewport: isInViewport,
    getCashedValue: function (paramName) {
        return window.breakpointsFeature[paramName];
    }
};

module.exports = commonFeature;
