// eslint-disable-next-line
window.jQuery = window.$ = require("jquery");
var processInclude = require("base/util");
var commonFeature = require("./components/commonFeature");
var { slickInit } = require("./components/slickCarousel");
commonFeature.init();
import "slick-carousel";

$(document).ready(function () {
    processInclude(require("./components/menu"));
    processInclude(require("base/components/cookie"));
    processInclude(require("base/components/consentTracking"));
    processInclude(require("./components/footer"));
    processInclude(require("./components/header"));
    processInclude(require("./components/miniCart"));
    processInclude(require("./components/formFields"));
    processInclude(require("./components/collapsibleItem"));
    processInclude(require("./components/search"));
    processInclude(require("./components/clientSideValidation"));
    processInclude(require("base/components/countrySelector"));
    processInclude(require("./components/tooltip"));
    processInclude(require("gtm/main"));
    processInclude(require("synchrony/main"));
    processInclude(require("./components/phoneMask"));
    processInclude(require("./components/soci"));
    processInclude(require("pixel/main"));
    processInclude(require("pinterest/main"));
    processInclude(require("./components/contentAssetModal"));

    commonFeature.initEvents();
    slickInit();
});

require("./thirdParty/bootstrap");
require("base/components/spinner");
