var cart = require("../cart/cart");
var saveForLaterList = require("../product/saveForLaterList");
var wishlist = require("../product/wishlist");
/* eslint-disable no-undef */

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    $.spinner().stop();
    var status = data.success ? "alert-success" : "alert-danger";

    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append("<div class='add-to-wishlist-messages '></div>");
    }
    $(".add-to-wishlist-messages")
        .append("<div class='add-to-wishlist-alert text-center " + status + " alert'>" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 6500);
}

function addToList($actionBtn) {
    var url = $actionBtn.data("url");
    var pid = $actionBtn.data("pid");

    // drive commerve customizer related data
    var recipeId = $actionBtn.data("recipeId");
    var recipeLocation = $actionBtn.data("recipeLocation");
    var listType = $actionBtn.data("listType");

    var optionId = $actionBtn.closest(".product-info").find(".lineItem-options-values").data("option-id");
    var optionVal = $actionBtn.closest(".product-info").find(".lineItem-options-values").data("value-id");
    optionId = optionId || null;
    optionVal = optionVal || null;
    if (!url || !pid) {
        return;
    }

    var removeUrl = $($actionBtn).data("action");
    var uuid = $actionBtn.data("uuid");
    var bundleParentName = $actionBtn.data("bundleParentName");
    var bundleParentSKU = $actionBtn.data("bundleParentSku");

    $.spinner().start();
    $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: {
            pid: pid,
            optionId: optionId,
            optionVal: optionVal,
            recipe: JSON.stringify({
                id: recipeId,
                location: recipeLocation
            }),
            bundleParentSKU: bundleParentSKU,
            bundleParentName: bundleParentName,
            type: listType
        },
        success: function (data) {
            displayMessage(data);
            $("body").trigger("removeItemFromCartAjax", [removeUrl, uuid]);
        },
        error: function (err) {
            displayMessage(err);
        }
    });
}


module.exports = function () {
    cart();

    $(".minicart").on("count:update", function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $(".minicart .minicart-quantity").text(count.quantityTotal);
            $(".minicart .minicart-link").attr({
                "aria-label": count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            if (count.quantityTotal > 0) {
                $(".minicart-quantity").removeClass("d-none");
                $(".minicart-link").removeClass("minicart-empty");
            } else {
                $(".minicart-quantity").addClass("d-none");
                $(".minicart-link").addClass("minicart-empty");
            }
        }
    });

    $(".minicart")
        .off("click")
        .on("click", function () {
            if ($(".search:visible").length === 0) {
                return;
            }
            var url = $(".minicart").data("action-url");
            var count = parseInt($(".minicart .minicart-quantity").text(), 10);
            if (count !== 0 && $(".minicart .popover.show").length === 0 && $(".cart-wrapper").length === 0) {
                $(".minicart .popover").addClass("show");
                $(".minicart .popover").spinner().start();
                $(".modal-background").addClass("d-block");

                $.get(url, function (data) {
                    if (data.indexOf("product-line-item") < 0) {
                        window.location.href = $(".minicart .popover").data("cartRedirect");
                    } else {
                        $(".minicart .popover").empty();
                        $(".minicart .popover").append(data);

                        var isPaypalEnabled = !!(
                            $("#paypal_enabled").length > 0 && document.getElementById("paypal_enabled").value === "true"
                        );
                        var isGooglePayEnabled = !!(
                            $("#isGooglePayEnabled").length > 0 && $("#isGooglePayEnabled").val() === "true"
                        );
    
                        if (isPaypalEnabled) {
                            paypalhelper.paypalMini();
                        }
                        if (isGooglePayEnabled) {
                            onGooglePayLoaded();
                        }
    
                        $.spinner().stop();
                    }
                }).then(function () {
                    wishlist.addToWishlist();
                    saveForLaterList.addToList();
                });
            }
        });


    $("body").on("click", function (e) {
        if ($(".minicart").has(e.target).length <= 0) {
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show");
            $(".modal-background").removeClass("d-block");
        }
    });

    $("body").on("change", ".minicart .quantity", function () {
        if ($(this).parents(".bonus-product-line-item").length && $(".cart-page").length) {
            // eslint-disable-next-line
            location.reload();
        }
    });

    $("body").on("click", ".minicart-remove-btn", function () {
        $(".minicart .popover").empty();
        $(".minicart .popover").removeClass("show");
        $(".modal-background").removeClass("d-block");
    });

    $("body").on("click", "#cartMoveWishlistModal .move-to-wishlist-confirmation", function (e) {
        e.preventDefault();
        var $actionBtn = $(this);
        addToList($actionBtn);        
    });

    $("body").on("click", "#cartMoveSaveForLater .move-to-wishlist-confirmation", function (e) {
        e.preventDefault();
        var $actionBtn = $(this);
        addToList($actionBtn);
    });

    // drive commerve customizer code start
    $("body").on("click", ".cart-move-drive-customizer", function (e) {
        e.preventDefault();

        // doesn't continue if this button is opening a confirmation modal
        if ($(this).data("toggle") === "modal") {
            return;
        }

        var url = $(this).attr("href") || $(this).data("url");
        var pid = $(this).data("pid");
        var recipeId = $(this).data("recipeId") || null;
        var recipeLocation = $(this).data("recipeLocation") || null;
        var listType = $(this).data("listType") || 10;

        if (!url || !recipeId || !recipeLocation) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                pid: pid,
                type: listType,
                recipe: JSON.stringify({
                    id: recipeId,
                    location: recipeLocation
                })
            },
            success: function (data) {
                displayMessage(data);
            },
            error: function (err) {
                displayMessage(err);
            }
        });
    });
    // drive commerve customizer code end

    $(window).on("breakpoint:change", function () {
        $(".minicart-remove-btn").trigger("click");
    });

};
