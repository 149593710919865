var formHelpers = require("base/checkout/formErrors");
var syfPosTokenUrl = $(".synchrony-payment-tab").data("pos-token");
var syfTransactStatusUrl = $(".synchrony-payment-tab").data("status-inquiry");
var response = {};
var mppToken;
var clientTransId;

var exports = function () {
    $("body").on("click", ".credit-card-tab", function (e) {
        e.preventDefault();
        $("#credit-card-content").show();
    });

    $("body").on("click", ".affirm-payment-tab", function (e) {
        e.preventDefault();
        $("#affirm-payment-content").show();
    });



    $("body").on("click", ".synchrony-payment-tab", function (e) {
        e.preventDefault();
        $.spinner().start();
        $("#credit-card-content").hide();
        $("#affirm-payment-content").hide();
        $("#paypal-content").hide();
        var url = $(this).data("url");

        if (url) {
            $.ajax({
                url: url,
                method: "GET",
                success: function (data) {
                    response = data.syfJSON;
                    $("#submit-order").addClass("synchrony_payment");
                    clientTransId = response.clientTransId ? response.clientTransId : "";
                },
                error: function (err) {
                    throw new Error(err);
                }
            });
        }

        formHelpers.clearPreviousErrors(".payment-form");
        $.spinner().stop();
    });

    $(".synchrony_payment").removeClass("checkout-hidden");

    $("body").on("click", ".synchrony_payment", function (e) { // eslint-disable-line consistent-return
        e.preventDefault();
        $("body").trigger("checkout:disableButton", ".next-step-button button");
        if ($(".payment-information").data("payment-method-id") !== "SYNCHRONY_PAYMENT") {
            return true;
        }
        var syfJson = {};
        syfJson.syfPartnerId = "PI20800280";
        syfJson.merchantID = "";
        syfJson.processInd = "3";
        syfJson.clientTransId = clientTransId;

        syfJson.custAddress1 = document.querySelector(".address1").innerText;
        syfJson.custCity = document.querySelector(".city").innerText.replaceAll(",", "");
        syfJson.custState = document.querySelector(".stateCode").innerText;
        var zipCode = document.querySelector(".postalCode").innerText;
        syfJson.custZipCode = zipCode.length > 5 ? zipCode.slice(0, -5) : zipCode;
        syfJson.phoneNumber = document.querySelector(".order-summary-phone").innerText;
        syfJson.transAmount1 = document.querySelector(".grand-total-sum").innerText.replaceAll(",", "").slice(1);

        $.ajax({
            url: syfPosTokenUrl,
            method: "GET",
            success: function (data) {
                if (data.mppToken) {
                    mppToken = data.mppToken;
                    syfJson.tokenId = mppToken;
                    // call Synchrony combined modal
                    syfMPP.calldBuyProcess(null, syfJson); // eslint-disable-line no-undef
                }
            },
            error: function (err) {
                throw new Error(err);
            }
        });
    });

    window.addEventListener("message", function (event) {
        if (
            (typeof event.data == "string" || typeof event.data == "object") &&
            (event.data == "Close Model" ||
                event.data == "Return To Merchant Shipping" ||
                event.data == "Close" ||
                event.data.action == "setPayCloseModal")
        ) {
            $.ajax({
                url: syfTransactStatusUrl,
                method: "GET",
                data: {mppToken: mppToken},
                success: function (data) {
                    if (data.error) {
                        $(".error-message").show();
                        $(".error-message-text").text(data.errMsg);
                    } else {
                        $.spinner().start();
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    throw new Error(err);
                }
            });
        }
    });
};

module.exports = exports;
