/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $("body").removeClass("modal-open");
    $("header").siblings().attr("aria-hidden", "false");
    $(".header").removeClass("menu-opened");
    $(".modal-background").removeClass("close-search-modal").hide();
    $(".search-toggler").removeClass("d-none");
    $(".navbar-toggler").removeClass("d-none");
    $(".store-locator-button").removeClass("d-none");
    $(".close-search-button").addClass("d-none");
    $(".site-search").removeClass("in");
    $(".site-search").attr("aria-hidden", "true");
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $("input.search-field").val("");
    $(".site-search .reset-button").addClass("d-none");
    clearModals();
    $(".search-mobile .suggestions").unbind("scroll");
    $(".suggestions-inner").empty();
    $(".site-search").css("height", "inherit");
    $(".popular-suggestions").removeClass("d-none");
}

module.exports = {
    tearDownSuggestions: tearDownSuggestions
};
