var scrollAnimate = require("./scrollAnimate");
const commonFeature = require("./commonFeature");
const debounce = require("lodash/debounce");

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var emailField = $("#footer-input");
    if (data.success) {
        emailField.addClass("is-valid");
        emailField.removeClass("is-invalid");
    } else {
        emailField.addClass("is-invalid");
        emailField.removeClass("is-valid");
    }

    var errorElement = $(".footer-email-signup .invalid-feedback");
    var successElement = $(".footer-email-signup .valid-feedback");

    if (errorElement) {
        $(errorElement).empty().append(data.msg).addClass("is-invalid");
    }

    if (successElement) {
        $(successElement).empty().append(data.msg).addClass("is-valid");
    }

    if (emailField.hasClass("is-valid")) {
        $(errorElement).addClass("hide");
    } else {
        $(errorElement).removeClass("hide");
    }

    $(button).attr("disabled", false);
}

function positionBtnBackToTop() {
    let $backToTop = $(".back-to-top");
    
    if ($(window).scrollTop() >= ($(window).height() / 100) * 10) {
        $backToTop.addClass("show");       
    } else {
        $backToTop.removeClass("show");
    }
}

module.exports = function () {
    $(".back-to-top").click(function () {
        scrollAnimate();
    });

    $(document).ready(function () {
        if (commonFeature.isBreakpoint(">=md")) {
            $(".footer-navigation .item__sublinks").removeClass("collapse");
            $(".footer-navigation .item__main-link").removeAttr("data-toggle");
        }
    });

    $(window).on("breakpoint:change", function () {
        if (commonFeature.isBreakpoint(">=md")) {
            $(".footer-navigation .item__sublinks").removeClass("collapse");
            $(".footer-navigation .item__main-link").removeAttr("data-toggle");
        } else {
            $(".footer-navigation .item__sublinks").addClass("collapse");
            $(".footer-navigation .item__main-link").attr("data-toggle", "collapse");
        }
    });

    $(window).on("scroll", debounce(positionBtnBackToTop, 50));

    $("#footer-input").on("change keyup", function () {
        $("#footer-input").removeClass("is-invalid is-valid");
        $(".invalid-feedback").contents().filter(function () {
            return this.nodeType === 3;
        }).remove();
        $(".valid-feedback").contents().filter(function () {
            return this.nodeType === 3;
        }).remove();
    });

    $(".subscribe-email").on("click", function (e) {
        e.preventDefault();
        var button = $(this);
        var url = button.data("href");

        var emailField = $("#footer-input");
        var emailId = emailField.val();
        $.spinner().start();
        button.attr("disabled", true);

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $(".footer-email-signup form").on("submit", function (e) {
        e.preventDefault();
        var error = true;

        // Validate email
        var email = $(this).find("input#email").val();
        var isValidEmail = email && /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(email) ? true : false; 

        if (isValidEmail) {
            // Remove the iframe if already exists
            if (document.querySelectorAll("#web2leadIFrame").length) {
                document.querySelector("#web2leadIFrame").remove();
            }

            // Create new iframe
            var web2leadIFrame = document.createElement("iframe");
            web2leadIFrame.id = "web2leadIFrame";
            web2leadIFrame.style.display = "none";
            document.body.appendChild(web2leadIFrame);

            // Append values and submit iframe form
            web2leadIFrame = document.querySelector("#web2leadIFrame");
            if (web2leadIFrame) {
                var web2leadIFrameContent = web2leadIFrame.contentDocument || web2leadIFrame.contentWindow.document;
                web2leadIFrameContent.body.appendChild(this.cloneNode(true));
                web2leadIFrameContent.body.querySelector("form").submit();
                error = false;
            }

            // make call to sfsc with email
            if ($(this).closest(".footer-content").data("save-session-email-url")) {
                var sfscVerifyUrl = $(this).closest(".footer-content").data("save-session-email-url");
                var data = $(this).serialize();
                $.ajax({
                    url: sfscVerifyUrl,
                    data: data,
                    type: "post"
                });
            }
        }

        // Reset original form
        if (!error) {
            this.reset();
        }

        // Handle error msg
        $(".footer-email-signup .response-msg .success-msg").addClass("d-none");
        $(".footer-email-signup .response-msg .error-msg").addClass("d-none");
        if (error) {
            $(".footer-email-signup .response-msg .error-msg").removeClass("d-none");
        } else {
            $(".footer-email-signup .response-msg .success-msg").removeClass("d-none");
        }
    });

    $(".footer-email-signup input#email").on("input", function () {
        $(".footer-email-signup .response-msg .success-msg").addClass("d-none");
        $(".footer-email-signup .response-msg .error-msg").addClass("d-none");
    });

    $("form#web2leadForm").on("submit", function () {
        // Validate email
        var email = $(this).find("input#email").val();
        var isValidEmail = email && /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(email) ? true : false; 

        if (isValidEmail) {
            // make call to sfsc with email
            if ($(".footer-content").data("save-session-email-url")) {
                var sfscVerifyUrl = $(".footer-content").data("save-session-email-url");
                var data = $(this).serialize();
                $.ajax({
                    url: sfscVerifyUrl,
                    data: data,
                    type: "post"
                });
            }
        }
    });
};
