module.exports = function () {
    $("body").on("pixel:trackEvent", function (event, data) {
        var pixelEnabled = $("#metaPixelID").data("meta-pixel-id");
        if (pixelEnabled && data && data.event) {
            var ecommerce = data.data ? data.data : null;
            fbq("track", data.event, ecommerce); // eslint-disable-line
            console.log("Meta Pixel Test:","event: ", data.event, "object value if needed: ", ecommerce); // eslint-disable-line
        }
    });

    $(document).on("click", "button.add-to-cart-drive-customizer", function () {
        var fbqPixelPID = $(".pixel-add-to-cart").data("pixel-add-to-cart-pid");
        if (!fbqPixelPID) {
            fbqPixelPID = $(this).data("pid");
        }
        var addToCartObj = {
            content_type: "product_group",
            content_ids: [fbqPixelPID]
        };
        $("body").trigger("pixel:trackEvent", {
            event: "AddToCart",
            data: addToCartObj
        });
    });

    $(document).on("submit", "form[name='simpleSearch']", function () {
        var $searchInput = $(this).find("input.search-field");
        var searchObj = {
            search_string: $searchInput.val()
        };
        $("body").trigger("pixel:trackEvent", {
            event: "Search",
            data: searchObj
        });
    });

    $(window).on("load", function () {
        var pixelPurchaseObj = $(".pixel-purchase").data("pixel-purchase");
        if (pixelPurchaseObj) {
            $("body").trigger("pixel:trackEvent", {
                event: "Purchase",
                data: pixelPurchaseObj
            });
        }
    });
};
