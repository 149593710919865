
const debounce = require("lodash/debounce");
const commonFeature = require("./commonFeature");
const searchHelpers = require("./searchHelpers");

let $cache = {};

let initCache = function () {
    $cache.window = $(window);
    $cache.header = $("header");
    $cache.mainContent = $("#maincontent");
    $cache.headerBanner = $(".header-banner");
    $cache.navigation = $(".header-wrapper");
    $cache.menu = $(".multilevel-dropdown .custom-dropdown-menu");
    $cache.minicart = $(".minicart .popover");
    $cache.searchRefinementGridHeader = $(".search-results .sticky-grid-header-wrapper");
    $cache.headerBannerHeight = $cache.headerBanner.length ? $cache.headerBanner.outerHeight() : 0;
    $cache.navigationHeight = $cache.navigation.length ? $cache.navigation.outerHeight() : 0;
    $cache.headerHeight = $cache.headerBannerHeight + $cache.navigationHeight;
    $cache.lastScrollTop = 0;
};

function updateSearchRefinementStickyGridHeader() {
    if ($cache.navigation && $cache.navigation) {
        var navHeaderOffsetTop = $cache.navigation.offset().top;
        var navHeaderHeight = $cache.navigation.height();
        var windowScrollTop = $cache.window.scrollTop();
        var gridHeaderCSSTop = navHeaderOffsetTop + navHeaderHeight - windowScrollTop;

        $cache.searchRefinementGridHeader.css("top", gridHeaderCSSTop < 0 ? 0 : gridHeaderCSSTop);
    }
}

function handleHeaderBannerPosition() {
    if (commonFeature.isBreakpoint(">=md")) {
        let scrollFromTop = $cache.window.scrollTop();

        if (scrollFromTop == 0) {
            $cache.headerBanner.css("top", 0);
            $cache.navigation.css({"top": $cache.headerBannerHeight});
            $cache.mainContent.css("padding-top", $cache.headerHeight);
            $cache.menu.css({"top": $cache.headerHeight});
            $cache.minicart.css({"height": $cache.window.height() - $cache.headerHeight});
        } else if (scrollFromTop < $cache.lastScrollTop || (scrollFromTop > $cache.headerBannerHeight && scrollFromTop + $cache.window.height() < $(document).height())) {
            // Scroll Up or Down
            $cache.headerBanner.css("top", -$cache.headerBannerHeight);
            $cache.navigation.css({"top": 0});
            $cache.mainContent.css("padding-top", $cache.headerHeight);
            $cache.menu.css({"top": $cache.navigationHeight});
            $cache.minicart.css({"height": $cache.window.height() - $cache.navigationHeight});
            $cache.searchRefinementGridHeader.css("top", $cache.navigation.height());
        }

        $cache.lastScrollTop = scrollFromTop;
    }
}

function handleHeaderMobilePosition() {
    if (!commonFeature.isBreakpoint(">=md")) {
        let scrollFromTop = $cache.window.scrollTop();

        if (scrollFromTop == 0) {
            $cache.header.css("top", 0);
            $cache.mainContent.css("padding-top", $cache.headerHeight);
        } else if (scrollFromTop < $cache.lastScrollTop) {
            // Scroll Up
            $cache.header.css("top", 0);
            $cache.mainContent.css("padding-top", $cache.headerHeight);
            $cache.searchRefinementGridHeader.css("top", $cache.navigation.height() + $cache.headerBanner.height());
        } else if (scrollFromTop > $cache.headerHeight && scrollFromTop + $cache.window.height() < $(document).height()) {
            // Scroll Down
            $cache.header.css("top", -$cache.headerHeight);
            $cache.mainContent.css("padding-top", $cache.headerHeight);
            $cache.searchRefinementGridHeader.css("top", 0);
        }

        if ($(".modal-background.close-search-modal").length && !$(".search-mobile .suggestions-wrapper .suggestions-inner").children().length) {
            searchHelpers.tearDownSuggestions();
        }

        $cache.lastScrollTop = scrollFromTop;
    }
}

function handleHeaderPosition() {
    if (commonFeature.isBreakpoint(">=md")) {
        $cache.navigation.css({"top": $cache.headerBannerHeight});
        $cache.mainContent.css({"padding-top": $cache.headerHeight});
        if (commonFeature.isBreakpoint(">=customLG")) {
            $cache.menu.css({"top": $cache.headerHeight});
        } else {
            $cache.menu.css({"top": 0});
        }
        $cache.minicart.css({"height": $cache.window.height() - $cache.headerHeight});

        $cache.window.on("scroll", debounce(handleHeaderBannerPosition, 50));
    } else {
        $cache.navigation.css({"top": 0});
        $cache.mainContent.css({"padding-top": $cache.headerHeight});
        $cache.menu.css({"top": 0});
        $cache.minicart.css({"height": "100%"});

        $cache.window.on("scroll", debounce(handleHeaderMobilePosition, 50));
    }
}

module.exports = function () {
    initCache();
    handleHeaderPosition();

    $cache.window.on("breakpoint:change", function () {
        initCache();

        $cache.headerBanner.css("top", 0);
        $cache.header.css("top", 0);

        handleHeaderPosition();
    });

    $cache.window.on("windowresize:width", function () {
        if ($cache.searchRefinementGridHeader && $cache.searchRefinementGridHeader.length) {
            updateSearchRefinementStickyGridHeader();
        }
    });
};
