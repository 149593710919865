module.exports = function () {
    var sizes = ["xs", "sm", "md", "lg", "customLG", "xl", "xxl"];

    sizes.forEach(function (size) {
        var selector = ".collapsible-" + size + " .title";
        $("body").on("click", selector, function (e) {
            var $actionBtn = $(selector).find("a");
            if ($actionBtn.length > 0 && $actionBtn.has(e.target).length > 0) {
                return;
            }
            e.preventDefault();
            $(this).parent(".collapsible-" + size).toggleClass("active");

            if ($(this).parent(".collapsible-" + size).hasClass("active")) {
                $(this).attr("aria-expanded", true);
            } else {
                $(this).attr("aria-expanded", false);
            }
        });
    });
};
