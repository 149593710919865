/**
 * updates GTM tags on selection of variants
 * @param {Object} data response from Product-Variation
 */

module.exports = {
    updateGTM: function (data, $productContainer) { // eslint-disable-line consistent-return
        if (data.gtmItem) {
            var gtmObj;
            try {
                gtmObj = JSON.stringify(data.gtmItem); // eslint-disable-line block-scoped-var
            } catch (err) {
                return false;
            }

            var html = "<div class='gtm-view-item' data-view-item='" + gtmObj + "'></div>"; // eslint-disable-line block-scoped-var
            $productContainer.find(".gtm-view-item-container").empty().html(html); // eslint-disable-line block-scoped-var
            $("body").trigger("gtm:standartEvent", {
                event: "view_item",
                data: JSON.parse(data.gtmItem)
            });
        }
    
        if (data.gtmAddToCart) {
            var html = "<div class='gtm-add-to-cart' data-gtm-add-to-cart='" + data.gtmAddToCart + "'></div>"; // eslint-disable-line
            $productContainer.find(".gtm-add-to-cart-container").empty().html(html); // eslint-disable-line block-scoped-var
        }
    
        if (data.gtmAddToWishlist) {
            var html = "<div class='gtm-add-to-wishlist' data-add-to-wishlist='" + data.gtmAddToWishlist + "'></div>"; // eslint-disable-line
            $productContainer.find(".gtm-add-to-wishlist-container").empty().html(html); // eslint-disable-line block-scoped-var
        }
    }
};
