/**
 * renderOpeningHours - renders opening hours
 * @param {*} schedule
 */
function renderOpeningHours(schedule) {
    Object.keys(schedule).forEach(function (key) {
        if (schedule[key].isClosed) {
            $(".day_"+key+".operation_time").text(schedule[key].open);
        } else {
            $(".day_"+key+".operation_time").text(schedule[key].openHours);
        }
    });
}

/**
 * renderError - renders error
 */
function renderError() {
    $(".header__store-locator").attr("data-store", "error").siblings(".store-locator-button").removeClass("has-store");
    $(".change__location-form").addClass("show");
    $(".change__location-form-label").removeClass("hide");
    $(".invalid-feedback .error-msg").removeClass("d-none");
    $(".store-locator-form input").addClass("is-invalid");
    $(".navbar__store-button").removeClass("has-store");
}

/**
 * searchStore - renders search store modal
 */
function searchStore() {
    $(".store-locator-button").on("click", function (e) {
        e.preventDefault();

        if ($(".menu-toggleable-left").hasClass("in")) {
            $(".navbar-header .close-button").trigger("click");
        } else if ($(".header-wrapper").hasClass("menu-opened")) {
            $(".modal-background.close-menu-modal").trigger("click");
        }

        $(this).parents(".header").addClass("store-locator-opened");
    });

    $(".header__store-locator").on("click", function (e) {
        e.stopPropagation();
    });

    // Init backdrop
    $(document).on("click", ".modal-background.close-store-locator-modal", function () {
        $(this).removeClass("close-store-locator-modal").hide();
        $(".header").removeClass("store-locator-opened");
    });

    $(".dropdown-store-locator").on("shown.bs.dropdown", function () {
        $(".modal-background").addClass("close-store-locator-modal").show();
    });

    $(".dropdown-store-locator").on("hide.bs.dropdown", function () {
        $(".modal-background").removeClass("close-store-locator-modal").hide();
        $(".header").removeClass("store-locator-opened");
    });

    // Menu Button
    $(".navbar__store-button").on("click", function (e) {
        e.stopPropagation();
        $(".close-button").trigger("click");
        $("#dropdownSearchStoreMobile").dropdown("toggle");
        $(this).parents(".header").addClass("store-locator-opened");
    });

    $(".change__location").on("click", function () {
        $(".change__location-form").collapse("toggle");

        $(this).toggleClass("open");

        if ($(this).hasClass("open")) {
            $(this).attr("aria-expanded", true);
        } else {
            $(this).attr("aria-expanded", false);
        }
    });

    $("body").on("submit", "form.store-locator-form:visible", function (e) {
        e.preventDefault();

        const $form = $(this);
        const url = $form.attr("action");
        const zipCode = $form.find("#zipCode").val();
        const $zipCodeField = $form.find("#zipCode");
        $form.spinner().start();

        if (Number.isNaN(Number(zipCode)) || zipCode.length != 5) {
            renderError();
            $form.spinner().stop();
        } else {
            if (url) {
                $.ajax({
                    url: url,
                    type: "post",
                    dataType: "json",
                    data: $form.serialize(),
                    success: function (data) {
                        $form.spinner().stop();
                        $(".store").removeClass("d-none");
                        if ("clientkey" in data.store && data.store.clientkey) {
                            $(".header__store-locator").attr("data-store", "store").siblings(".store-locator-button").addClass("has-store");
                            $(".change__location-form").removeClass("show");
                            $(".change__location-form-label").addClass("hide");
                            $(".navbar__store-button").addClass("has-store");
                            $(".navbar__store-name").text(data.store.city + " ," + data.store.state);
                            $(".header__store-name").text(data.store.city);
                            $(".store__name").text(data.store.name);
                            $(".store__location-name").text(data.store.storedisplayname);
                            $(".store__address_line").text(data.store.address1);
                            $(".store__address_city").text(data.store.city);
                            $(".store__address_state").text(data.store.state);
                            $(".store__address_postalcode").text(data.store.postalcode);
                            $(".store__phone").text(data.store.phone);
                            $(".store__link").attr("href", "http://" + data.store.website_url);
                            $(".store__location-link").attr("href", "http://maps.google.com/maps?q=" + data.store.address1 + "," + data.store.city + "," + data.store.state + "," + data.store.postalcode);
                            $(".store__appointment").attr("href", data.store.appt_url);

                            renderOpeningHours(data.store.schedule);

                        } else {
                            renderError();
                        };
                        $zipCodeField.val("").removeClass("field-with-value");
                    },
                    error: function () {
                        renderError();
                        $form.spinner().stop();
                        $zipCodeField.val("").removeClass("field-with-value");
                    }
                });
            };

        }

        return false;
    });
}

module.exports = {
    searchStore: searchStore
};
