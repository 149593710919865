module.exports = function (element) {
    var headerBanner = $(".header-banner");
    var navigation = $(".header-wrapper");
    var headerBannerHeight = headerBanner.length ? headerBanner.outerHeight() : 0;
    var navigationHeight = navigation.length ? navigation.outerHeight() : 0;
    var headerHeight = headerBannerHeight + navigationHeight;

    var position = element && element.length ? element.offset().top - headerHeight : 0;

    $("html, body").animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $(".logo-home").focus();
    }
};
